import React, { Fragment } from 'react'
import { Container, CardBody, Input, Col, Form, Row, Label } from 'reactstrap'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { myStoryOperations } from '../../state/ducks/story'
import { connect } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify'
import { Save } from '../../Constant'
import GoogleFontLoader from 'react-google-font-loader'
import '../Button.css'
import ImageResize from 'quill-image-resize-module-react'
import Header from '../../Layout/Header'
import Footer from '../../Layout/Footer'
import Sidebar from '../../Layout/Sidebar'
import CustomizerContext from '../../_helper/Customizer'

Quill.register('modules/imageResize', ImageResize)
class Edit extends React.Component {
  static contextType = CustomizerContext
  constructor(props) {
    super(props)
    this.quill = React.createRef()
    this.state = {
      text: '',
      imagePreviews: [],
      selectedOption: '',
      numCols: 1,
      onAdd: false,
      selectedQuestionData: '',
      showInputField: false,
      selectedCategory: '',
      showSecondDropdown: false,
      selectedContributor: '',
      contributor: [],
      isEditing: false,
      contributor_name: '',
      email: '',
      content: '',
      hashkey: '',
    }
    this.h2Ref = React.createRef()
    this.toggleEdit = this.toggleEdit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.imageHandler = this.imageHandler.bind(this)
    this.saveCursorPosition = this.saveCursorPosition.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  async componentDidMount() {
    this.startUpdateInterval()
    const questionId = localStorage.getItem('selectedQuestionId')
    const response = await this.props.listContributorQuestionDetails({
      id: questionId,
    })
    if (response && response.payload) {
      const questionData = response.payload
      // Retrieve the hash key from localStorage
      const hashkey = localStorage.getItem('contributorHashKey')
      this.setState({
        selectedQuestionData: questionData,
        selectedOption: questionData.status,
        text: questionData.answer,
        content: questionData.question,
        hashkey: hashkey,
      })
    }
  }

  handleHashKeyChange = (newHashKey) => {
    this.setState({ hashkey: newHashKey })
  }

  handleChange(value) {
    this.setState({ text: value })
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  }

  startUpdateInterval() {
    this.intervalId = setInterval(() => {
      this.updateAutomatically()
    }, 5000) // 5 seconds interval
  }

  updateAutomatically = async () => {
    try {
      const { selectedQuestionData, selectedOption, text, content, hashkey } =
        this.state

      let statusToUpdate =
        selectedOption === 'Not Started' || selectedOption === 'Complete'
          ? selectedOption
          : 'Ongoing'

      let updateResponse = await this.props.updateContributorQuestion({
        id: selectedQuestionData.id,
        question: content,
        status: selectedOption,
        answer: text,
        hashkey: hashkey,
      })

      this.setState({
        selectedOption: statusToUpdate,
      })
    } catch (err) {
      console.log(err)
    }
  }

  onUpdate = async (e) => {
    this.toggleEdit()
    e.preventDefault()

    try {
      const { selectedQuestionData, selectedOption, text, content, hashkey } =
        this.state

      this.setState({ isEditing: false })

      let updateResponse = await this.props.updateContributorQuestion({
        id: selectedQuestionData.id,
        question: content,
        status: selectedOption,
        answer: text,
        hashkey: hashkey,
      })
      toast.success(updateResponse.message, { autoClose: 10000 })
    } catch (err) {
      toast.error(err.updateResponse?.data?.message, { autoClose: 10000 })
      console.log(err)
    }
  }

  handleOptionChange = (e) => {
    const selectedOption = e.target.value
    this.setState({ selectedOption })
  }

  imageHandler() {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    input.onchange = () => {
      const file = input.files[0]
      const reader = new FileReader()

      reader.onload = (e) => {
        const imageUrl = e.target.result

        const editor = this.quill.getEditor()
        const range = editor.getSelection(true) // Get current selection

        // Insert a line break before the image to create space
        editor.insertText(range.index, '\n', Quill.sources.USER)

        // Insert the image
        const customImageHTML = `<div style="text-align: center;">
        <img src="${imageUrl}" style="max-width: 100%; margin: 20px 0;" />
      </div>`
        editor.clipboard.dangerouslyPasteHTML(range.index + 1, customImageHTML)

        // Insert a line break after the image to ensure space after it
        editor.insertText(range.index + 2, '\n', Quill.sources.USER)

        // Move the cursor to a new line after the image
        const newPosition = range.index + 3
        editor.setSelection(newPosition, Quill.sources.SILENT)

        // Insert an empty paragraph to set alignment properly
        editor.insertText(newPosition, '\n', Quill.sources.USER)

        // Reset text alignment to left (or the default alignment)
        editor.setSelection(newPosition + 1, Quill.sources.SILENT)
        editor.format('align', 'left', Quill.sources.USER)
      }

      reader.readAsDataURL(file)
    }
  }

  toggleEdit() {
    this.setState(
      (prevState) => ({
        isEditing: !prevState.isEditing,
      }),
      () => {
        if (this.state.isEditing && this.h2Ref.current) {
          //this.restoreCursorPosition();
          // this.h2Ref.current.focus();
        }
      }
    )
  }

  handleInputChange(event) {
    this.saveCursorPosition()
    this.setState({
      content: event.target.value,
    })
  }
  handleBlur() {
    this.setState({ isEditing: false })
  }

  saveCursorPosition() {
    const selection = window.getSelection()
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0)
      const preSelectionRange = range.cloneRange()
      preSelectionRange.selectNodeContents(this.h2Ref.current)
      preSelectionRange.setEnd(range.startContainer, range.startOffset)
      this.cursorPosition = preSelectionRange.toString().length
    }
  }

  render() {
    const { layout, sidebarIconType } = this.context

    const layout1 = localStorage.getItem('sidebar_layout') || layout
    const sideBarIcon =
      localStorage.getItem('sidebar_icon_type') || sidebarIconType
    const { isEditing, content, selectedQuestionData, selectedOption, text } =
      this.state
    const modules = {
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ align: [] }],
          ['link', 'image'],
          ['clean'],
        ],
        handlers: {
          image: this.imageHandler,
        },
      },

      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize', 'Toolbar'],
      },
    }

    return (
      <Fragment>
        <GoogleFontLoader
          fonts={[
            {
              font: 'Martel',
              weights: [300, 700],
            },
            {
              font: 'Fira Sans',
              weights: [300, 700, 400, 800],
            },
          ]}
        />
        <div
          className={`page-wrapper ${layout1}`}
          sidebar-layout={sideBarIcon}
          id="pageWrapper"
        >
          <Header isLoggedinHeader={true} />
          <div className="page-body-wrapper">
            <Sidebar isLoggedinHeader={true} />
            <Container fluid={true}>
              <CardBody>
                <Form onSubmit={this.onUpdate}>
                  <div className="editBoxDesign">
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                      {isEditing ? (
                        <input
                          ref={this.h2Ref}
                          type="text"
                          value={content}
                          onChange={this.handleInputChange}
                          style={{
                            width: '80%',
                            textAlign: 'center',
                            font: 'normal normal 300 2.2rem/1.4 Martel',
                            color: '#000',
                          }}
                          onBlur={this.toggleEdit}
                        />
                      ) : (
                        <h2
                          className="mt-5 mb-3"
                          style={{
                            width: '80%',
                            textAlign: 'center',
                            font: 'normal normal 300 2.2rem/1.4 Martel',
                            color: '#000',
                          }}
                          onClick={this.toggleEdit}
                        >
                          {content}
                        </h2>
                      )}
                    </Row>
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                      <div
                        style={{
                          textAlign: 'center',
                          font: 'normal normal 400 .65rem/1 Fira Sans',
                          textTransform: 'uppercase',
                          letterSpacing: '.08rem',
                          color: '#8A1A75',
                        }}
                      >
                        BY GIFT YOUR STORY ON {selectedQuestionData.date}
                      </div>
                      <div
                        className="mt-3"
                        style={{
                          display: 'block',
                          content: '',
                          height: '2px',
                          width: '20%',
                          background: '#7f2b72',
                        }}
                      ></div>
                      <br></br>
                    </Row>
                    <Row className="mt-3 mb-5">
                      <Col md="12">
                        <div
                          style={{
                            font: 'normal normal 300 1.0rem/1.5 Fira Sans',
                            color: '#000',
                            textAlign: 'center',
                            position: 'relative',
                            width: '98%',
                          }}
                        >
                          {selectedQuestionData.description}
                        </div>
                      </Col>
                    </Row>

                    <Row style={{ marginBottom: '5%' }}>
                      <Col md="12">
                        <ReactQuill
                          style={{ height: '400px' }}
                          ref={(el) => {
                            this.quill = el
                          }}
                          value={text}
                          onChange={this.handleChange}
                          theme="snow"
                          modules={modules}
                        />
                      </Col>
                    </Row>
                  </div>
                  <Row
                    style={{ display: 'flex', justifyContent: 'center' }}
                    className="mt-5"
                  >
                    <span
                      style={{
                        content: '',
                        height: '2px',
                        width: '20%',
                        background: '#7f2b72',
                      }}
                    ></span>
                  </Row>
                  <Row className="mt-5 mb-3">
                    <Col md="12">
                      <div
                        style={{
                          textAlign: 'center',
                          color: '#43107E',
                          letterSpacing: '.122rem',
                          font: 'normal normal 400 0.75rem/1 Fira Sans',
                        }}
                      >
                        STATUS
                      </div>
                      <div
                        className="mt-3 mb-5"
                        style={{
                          textAlign: 'center',
                          font: 'normal normal 300 1.0rem/1.5 Fira Sans',
                        }}
                      >
                        <label
                          style={{
                            background: '#f8eaf4',
                            padding: '2px 15px',
                            borderRadius: '18px',
                          }}
                        >
                          <input
                            type="radio"
                            value="Not Started"
                            checked={selectedOption === 'Not Started'}
                            onChange={this.handleOptionChange}
                          />
                          &nbsp;Not started
                        </label>
                        &nbsp;&nbsp;&nbsp;
                        <label
                          style={{
                            background: '#f8eaf4',
                            padding: '2px 15px',
                            borderRadius: '18px',
                          }}
                        >
                          <input
                            type="radio"
                            value="Ongoing"
                            checked={selectedOption === 'Ongoing'}
                            onChange={this.handleOptionChange}
                          />
                          &nbsp;Ongoing
                        </label>
                        &nbsp;&nbsp;&nbsp;
                        <label
                          style={{
                            background: '#f8eaf4',
                            padding: '2px 15px',
                            borderRadius: '18px',
                          }}
                        >
                          <input
                            type="radio"
                            value="Complete"
                            checked={selectedOption === 'Complete'}
                            onChange={this.handleOptionChange}
                          />
                          &nbsp;Complete
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-5"></Row>
                  <Row>
                    <div
                      style={{
                        position: 'fixed',
                        bottom: '0',
                        zIndex: '10000',
                        left: '0',
                        right: '0',
                        background: 'rgba(255,255,255,.7)',
                        padding: '20px 0px 20px 20px',
                        borderTop: '1px solid #e8d2e4',
                        textAlign: 'center',
                      }}
                    >
                      <button
                        type="submit"
                        style={{
                          background: 'white',
                          letterSpacing: '0.15rem',
                          border: '2px solid #8A1A75',
                          fontWeight: '300',
                          color: '#8a1975',
                          borderRadius: '28px',
                          padding: '0 30px',
                          textTransform: 'uppercase',
                          minHeight: '50px',
                          fontSize: '1.1rem',
                          fontFamily: 'Fira Sans',
                        }}
                      >
                        {Save}
                      </button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Container>
            <Footer />
          </div>
        </div>
      </Fragment>
    )
  }
}
const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = {
  listContributorQuestionDetails:
    myStoryOperations.listContributorQuestionDetails,
  updateContributorQuestion: myStoryOperations.updateContributorQuestion,
}

export default connect(mapStateToProps, mapDispatchToProps)(Edit)
